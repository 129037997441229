
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import MfaForm from './mfa-form';
import UserpassForm from './userpass-form';

export default {
        MfaForm,		UserpassForm
}
